import ChevronUp24 from '@carbon/icons-react/es/chevron--up/24';
import { assertUnreachable, makeClassNames } from 'lib/util';

import React from 'react';

type Direction = 'up' | 'right' | 'down' | 'left';

type Props = {
  className?: string;
  direction: Direction;
};

function rotationOfDir(direction: Direction) {
  switch (direction) {
    case 'up':
      return 'rotate-0';
    case 'right':
      return 'rotate-90';
    case 'down':
      return 'rotate-180';
    case 'left':
      return '-rotate-90';
    default:
      assertUnreachable(direction);
  }
}

const Chevron: React.FC<Props> = ({ className, direction }) => {
  return (
    <ChevronUp24
      className={makeClassNames(
        className,
        'transition-transform transform-gpu',
        rotationOfDir(direction)
      )}
    />
  );
};

export default Chevron;
