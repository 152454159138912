import { Heading, TextCase, TextSize } from 'components/atoms/typography';

import Balancer from 'react-wrap-balancer';
import { CdnImageDataFragment } from 'gatsby/graphqlTypes';
import { Container } from 'components/atoms/layout/Container';
import FullWidthImage from '@svelte/reactify/FullWidthImage';
import { LinkButton } from 'components/atoms/button';
import React from 'react';
import StaticImage from '@svelte/reactify/StaticImage';
import { makeProductCatPath } from 'lib/navigation/dynamic';
import { useAppNavTree } from 'state/context/nav';

type Props = {
  bannerImages: CdnImageDataFragment[];
};

const HeroBanner: React.FC<Props> = ({ bannerImages }) => {
  const navTree = useAppNavTree();
  const storageCatPage = makeProductCatPath(navTree, 'storage');

  const mainImageCdn = bannerImages[1];
  const bgImageCdn = bannerImages[0];
  const { alt: mainAlt, thumbhash: mainThumbHash } = bannerImages[1];
  const { alt: bgAlt, thumbhash: bgThumbHash } = bannerImages[0];

  if (
    !bgImageCdn ||
    !mainImageCdn ||
    !mainAlt ||
    !bgAlt ||
    !mainThumbHash ||
    !bgThumbHash
  ) {
    throw Error('Invalid Hero');
  }

  return (
    /* NOTE: do not use min-h-screen for this component as it messes up Lighthouse tests 
             In order to check what content would get loaded after scrolling to bottom, Lighthouse viewport is super tall
    */
    <div className="relative flex w-full flex-col items-center justify-center overflow-hidden md:h-body-minus-top-header md:flex-row">
      {/* Background */}
      <div className="absolute inset-0 h-full w-full">
        <FullWidthImage
          data={bgImageCdn}
          blur={[700]}
          colour={['F9D4A9', 60]}
          eager
        />
      </div>

      {/* Top/Left Image */}
      <div className="z-10 my-rhythm4 border-4 border-primary -rotate-3">
        <StaticImage
          display="hidden md:block"
          data={mainImageCdn}
          width={450}
          height={613}
          eager
        />
        <FullWidthImage
          data={mainImageCdn}
          display="block md:hidden"
          height="h-rhythm12 sm:h-rhythm10"
          // NOTE: this gravity is for particular image of woman with sisal bag
          gravity="south"
          eager
        />
      </div>

      {/* Bottom/Right block with text and CTA */}
      <div className="max-w-rhythm14">
        <Container className="relative z-10 pb-rhythm5 text-primary-700 md:pt-rhythm5">
          <Heading
            level={1}
            bold
            color=""
            mobileSize={TextSize.DoublePica}
            tabletSize={TextSize.DoublePica}
            withoutSpacing
            className="mb-rhythm0"
          >
            Sustainable
            <br />
            Storage Solutions
          </Heading>
          <Heading
            level={2}
            size={TextSize.Pica}
            color=""
            textCase={TextCase.None}
            leading=""
          >
            {/* <Balancer>
                  Take your plastic-free shampoo and conditioning to another
                  level.
                </Balancer> */}
            <Balancer ratio={0.8}>
              Helps extend the life of your shampoo, conditioning and shave
              bars.
            </Balancer>
          </Heading>

          <LinkButton
            to={storageCatPage}
            className="mt-rhythm0 max-w-rhythm8"
            size="s"
            color=""
          >
            Shop Storage
          </LinkButton>
        </Container>
      </div>
    </div>
  );
};

export default HeroBanner;
