import React from 'react';

export type Props = {
  className?: string;
  fill?: string;
};

const PrimaryLogo: React.FC<Props> = ({ className, fill = 'currentColor' }) => {
  return (
    <svg
      version="1.1"
      viewBox="0 0 647.67 836"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      className={className}
    >
      <g data-name="Layer 2">
        <g data-name="Layer 2">
          <path d="M161.72,0C340.57,0,485.56,145,485.56,323.83h0c0,178.84-145,323.83-323.84,323.83H647.67V0Z" />
          <rect width="161.38" height="647.67" />
          <path d="M67.86,719.22q0,19.66-11.07,27.4-10.22,7.15-28.07,7.16H1.7V752H8V688.76H1.7V687H31.2q18.33,0,27.5,7.49T67.86,719.22ZM26.62,752h3.63q9.64,0,13.79-6.83t4.16-22.87v-5.53q0-21.5-8.21-25.88-3.91-2.1-11.08-2.1H26.62Z" />
          <path d="M85.62,687h29.12v1.81h-5.25V752h5.25v1.81H85.62V752h5.25V688.76H85.62Z" />
          <path d="M159.62,752.73a14.35,14.35,0,0,0,8.45-2.58q3.68-2.58,3.67-7.78a9.13,9.13,0,0,0-4-8,37.75,37.75,0,0,0-11.27-4.82,77.42,77.42,0,0,1-10.6-3.58,28.43,28.43,0,0,1-6.25-4q-5.92-5-5.92-15.37A20,20,0,0,1,139.8,692q6.07-6.12,16.57-6.11a40.38,40.38,0,0,1,10.55,1.48,39.46,39.46,0,0,0,6.83,1.48c.89,0,1.68-.64,2.38-1.91h1.05l.67,19.28h-1.53a59.47,59.47,0,0,0-9.59-13.32q-5-5-10.89-5t-9.11,2.72a9,9,0,0,0-3.25,7.26,7.77,7.77,0,0,0,3.87,7,35,35,0,0,0,9.5,4q5.63,1.53,7.4,2.1c1.17.38,2.64.91,4.39,1.58a22.4,22.4,0,0,1,4.3,2.1,29.57,29.57,0,0,1,3.53,2.77,13.16,13.16,0,0,1,2.91,3.67A21.88,21.88,0,0,1,182,731.92q0,10.3-6.45,16.56t-16.94,6.25a62.65,62.65,0,0,1-12.46-1.43c-4.68-.95-7.28-1.43-7.78-1.43-.9,0-1.76,1-2.58,2.86h-1.34L133.46,732h1.62a52.3,52.3,0,0,0,10.74,14.51Q152.36,752.73,159.62,752.73Z" />
          <path d="M228.64,720h-5.06v32h6.21v1.81h-31V752H205V688.76h-6.21V687h28.83q12.42,0,17.62,1.29c3.47.85,5.9,1.89,7.3,3.1q4.59,3.72,4.59,11.84t-4,11.6q-4,3.48-12.22,4.15v.29q7.73,1,10.78,4.58t3.06,11.84v5.06a26.72,26.72,0,0,0,.53,6.68,2.15,2.15,0,0,0,2.24,1.72,3.09,3.09,0,0,0,2.72-1.57q1-1.57,1.86-6.64l1.62.19Q262.63,749.2,260,752t-10.46,2.76q-7.83,0-11.12-3.67t-3.29-13.8v-7c0-3.56-.47-6.17-1.39-7.83S231.13,720,228.64,720Zm-5.06-31.22v29.41h3.06c3.63,0,6.32-1.12,8.07-3.34s2.62-5.86,2.62-10.89v-2.77q0-7.54-2.53-10t-8.06-2.44Z" />
          <path d="m304.12 746.91q3 4.68 10.84 4.67t12-4.87q4.2-4.86 4.2-14.79v-43.16h-6.16v-1.76h13.94v1.81h-5.25v42.49q0 11.84-5.25 17.66t-18.57 5.82q-13.32 0-20.34-5.53t-7-19v-41.49h-5.25v-1.76h30.08v1.81h-6.21v44.68q-0.04 8.74 2.97 13.42z" />
          <path d="M353.34,687h28.07q14.41,0,21.48,4.15T410,706q0,11.84-8.59,16.32-8,4.11-22.43,4.11h-.77V752H390.1v1.81H353.34V752h6.2V688.76h-6.2Zm24.82,1.81v35.8h2q5.73,0,7.92-3.91t2.2-12.7v-4.58q0-8.11-2.06-11.36c-1.37-2.17-3.86-3.25-7.49-3.25Z" />
          <path d="M483.66,687l1,24H482.8q-4.2-12.69-7.45-17.33A10.73,10.73,0,0,0,466,689h-1.15v62.83H473v2H438.12v-2h8.11V689H445a10.84,10.84,0,0,0-9.45,4.82q-3.35,4.83-7.35,17.14h-1.81l.95-24Z" />
          <path d="m532.36 754.73q-15.95 0-23.83-8.64t-7.87-25q0-16.38 8.45-25.78t23.89-9.41q15.48 0 23.35 8.92t7.87 25.35q0 16.43-8 25.49t-23.86 9.07zm12.22-30.45v-7.07q0-17.48-1.72-22.05c-1.28-3.5-3.09-5.67-5.44-6.49a13.71 13.71 0 0 0-4.87-0.77 14.12 14.12 0 0 0-4.92 0.77 7.11 7.11 0 0 0-3.39 2.67 18.72 18.72 0 0 0-2.15 4 26.23 26.23 0 0 0-1.29 5.92q-0.57 5.54-0.57 16.32v6.88q0 11.65 0.91 16.56a20.88 20.88 0 0 0 2.24 7q3 4.68 9.17 4.68c5 0 8.29-2.12 9.78-6.35s2.25-11.57 2.25-22.07z" />
          <path d="M610.93,720h-5.06v32h6.21v1.81h-31V752h6.2V688.76h-6.2V687h28.83q12.4,0,17.62,1.29c3.46.85,5.9,1.89,7.3,3.1q4.59,3.72,4.58,11.84t-4,11.6q-4,3.48-12.22,4.15v.29q7.74,1,10.79,4.58T637,735.64v5.06a27.38,27.38,0,0,0,.52,6.68,2.17,2.17,0,0,0,2.25,1.72,3.09,3.09,0,0,0,2.72-1.57c.66-1,1.29-3.26,1.86-6.64l1.62.19q-1.05,8.11-3.68,10.89t-10.45,2.76q-7.83,0-11.12-3.67t-3.3-13.8v-7q0-5.34-1.38-7.83C615.12,720.81,613.41,720,610.93,720Zm-5.06-31.22v29.41h3.06c3.62,0,6.31-1.12,8.06-3.34s2.63-5.86,2.63-10.89v-2.77q0-7.54-2.53-10T609,688.76Z" />
          <path d="M99,835.7V793.38h5.37v37.55H128v4.77Z" />
          <path d="m190.21 836a18.15 18.15 0 0 1-8.11-1.79 19.74 19.74 0 0 1-6.29-4.86 22.77 22.77 0 0 1-5.51-14.81 21.79 21.79 0 0 1 1.52-8.07 22.39 22.39 0 0 1 4.23-6.86 20.76 20.76 0 0 1 6.35-4.77 18 18 0 0 1 7.93-1.75 17.62 17.62 0 0 1 8.1 1.84 20.13 20.13 0 0 1 6.26 5 23.22 23.22 0 0 1 4.05 6.91 22 22 0 0 1 1.43 7.81 22.1 22.1 0 0 1-5.69 14.93 20 20 0 0 1-6.34 4.74 18.29 18.29 0 0 1-7.93 1.68zm-14.49-21.46a18.74 18.74 0 0 0 1.05 6.23 17.12 17.12 0 0 0 2.95 5.34 14.18 14.18 0 0 0 4.59 3.69 13.64 13.64 0 0 0 12-0.06 14.32 14.32 0 0 0 4.56-3.81 17.54 17.54 0 0 0 2.86-5.37 19.21 19.21 0 0 0 1-6 18.81 18.81 0 0 0-1-6.2 17.52 17.52 0 0 0-3-5.33 14.23 14.23 0 0 0-4.59-3.7 13.72 13.72 0 0 0-11.95 0.06 14 14 0 0 0-4.56 3.79 18 18 0 0 0-2.89 5.33 18.43 18.43 0 0 0-1.02 6.03z" />
          <path d="M263.52,803.28V835.7h-5.36V793.38h4.52l25.87,33.08v-33h5.37V835.7H289Z" />
          <path d="m344.58 835.7v-42.32h14.66a21.21 21.21 0 0 1 11.35 2.83 17.79 17.79 0 0 1 6.83 7.63 24.63 24.63 0 0 1 2.26 10.64 23.79 23.79 0 0 1-2.5 11.21 17.91 17.91 0 0 1-7.09 7.39 21.5 21.5 0 0 1-10.85 2.62zm29.68-21.22a19.47 19.47 0 0 0-1.76-8.48 13.5 13.5 0 0 0-5.09-5.78 15.15 15.15 0 0 0-8.17-2.09h-9.3v32.78h9.3a14.72 14.72 0 0 0 8.22-2.17 13.81 13.81 0 0 0 5.07-5.9 19.75 19.75 0 0 0 1.73-8.36z" />
          <path d="m445 836a18.15 18.15 0 0 1-8.11-1.79 19.59 19.59 0 0 1-6.28-4.86 22.71 22.71 0 0 1-5.61-14.81 21.79 21.79 0 0 1 1.52-8.07 22.39 22.39 0 0 1 4.23-6.86 20.76 20.76 0 0 1 6.35-4.77 18 18 0 0 1 7.93-1.75 17.62 17.62 0 0 1 8.1 1.84 20.13 20.13 0 0 1 6.26 5 23.46 23.46 0 0 1 4.06 6.91 22.23 22.23 0 0 1-4.27 22.74 20 20 0 0 1-6.34 4.74 18.29 18.29 0 0 1-7.84 1.68zm-14.49-21.46a18.74 18.74 0 0 0 1.05 6.23 17.12 17.12 0 0 0 2.95 5.34 14.18 14.18 0 0 0 4.59 3.69 12.82 12.82 0 0 0 6 1.37 12.66 12.66 0 0 0 6.08-1.43 14.32 14.32 0 0 0 4.56-3.81 17.54 17.54 0 0 0 2.86-5.37 19.21 19.21 0 0 0 1-6 18.81 18.81 0 0 0-1-6.2 17.52 17.52 0 0 0-3-5.33 14.23 14.23 0 0 0-4.59-3.7 13.72 13.72 0 0 0-11.95 0.06 14 14 0 0 0-4.56 3.79 18 18 0 0 0-2.89 5.33 18.43 18.43 0 0 0-1.15 6.03z" />
          <path d="m518.26 803.28v32.42h-5.36v-42.32h4.52l25.87 33.08v-33h5.37v42.24h-4.89z" />
        </g>
      </g>
    </svg>
  );
};

export default PrimaryLogo;
