import { AppEventName } from 'lib/events/contracts';
import Balancer from 'react-wrap-balancer';
import { Container } from 'components/atoms/layout/Container';
import FullWidthImage from '@svelte/reactify/FullWidthImage';
import { Heading } from 'components/atoms/typography';
import { HomePageQuery } from 'gatsby/graphqlTypes';
import { LinkButton } from 'components/atoms/button';
import { MovingImage } from '@svelte/reactify/MovingImage';
import React from 'react';
import ResponsiveImage from '@svelte/reactify/ResponsiveImage';
import SlateContent from 'components/molecules/cms/SlateContent';
import TrackSectionView from 'lib/events/TrackSectionView';
import { makeClassNames } from 'lib/util';

type UspProps = Readonly<{
  eager?: boolean;
  blocks: NonNullable<NonNullable<HomePageQuery>['strapiHomePage']>['usp'];
}>;

const contentBorderWidth = 'border';
const lgMediaClassNames = makeClassNames(
  contentBorderWidth,
  'border-secondary',
  'rounded'
);
const SKINIMALIST_SLUG = '/uk/blog/skinimalism-simplifying-skincare-haircare';
const SOLID_BEAUTY_LINK = '/uk/best/solid-beauty';

const ContentLinks: React.FC<{ index: number }> = ({ index }) => {
  return (
    <>
      {index === 0 && (
        <LinkButton to={SOLID_BEAUTY_LINK}>Shop Solid Beauty</LinkButton>
      )}
      {index === 1 && (
        <LinkButton to={SKINIMALIST_SLUG}>What is skinimalism?</LinkButton>
      )}
    </>
  );
};

const Usp: React.FC<UspProps> = ({ eager = false, blocks }) => {
  return (
    <>
      <div className="border-b-8 border-primary bg-primary-200 lg:hidden">
        {blocks.map((block, index) => {
          if (!block || block.__typename === 'StrapiError') {
            return null;
          }

          let media: React.ReactNode | null = null;
          const icon = block.icon;

          if (block.__typename === 'StrapiComponentPageBlockWithVideo') {
            media = (
              <MovingImage
                className={makeClassNames(
                  'h-rhythm12 w-full object-cover',
                  index > 0 && 'border-t-8 border-primary'
                )}
                video={block.video}
              />
            );
          }

          if (
            block.__typename === 'StrapiComponentPageBlockWithOrientedImage'
          ) {
            const image = block.image.landscape;
            media = image && (
              <FullWidthImage
                data={image}
                className="border-t-8 border-primary"
                height="h-rhythm12"
                eager={eager}
              />
            );
          }

          return (
            <React.Fragment key={index}>
              <div className="border-b-8 border-primary">{media}</div>
              <Container
                className={makeClassNames(
                  'relative z-10 flex flex-col items-center pb-rhythm4 pt-6 text-center'
                )}
              >
                {icon && (
                  <div
                    className={makeClassNames(
                      'z-10 -mt-[4rem] h-rhythm7 w-rhythm7',
                      index !== 2 &&
                        'flex items-center rounded-full border-8 border-primary bg-pearl-200 p-4'
                    )}
                  >
                    <img
                      className="h-auto w-full"
                      loading={eager ? undefined : 'lazy'}
                      src={icon.url}
                    />
                  </div>
                )}
                <div className="pb-rhythm2 pt-rhythm0 sm:w-rhythm11">
                  <Heading level={2} withoutSpacing>
                    <Balancer>{block.title}</Balancer>
                  </Heading>
                </div>

                <SlateContent
                  content={block.c}
                  className="text-left sm:w-rhythm11"
                />
                <div className="sm:w-rhythm11">
                  <ContentLinks index={index} />
                </div>
              </Container>
            </React.Fragment>
          );
        })}
      </div>
      <div className="hidden bg-primary-200 py-rhythm3 lg:block">
        <Container className="flex flex-col space-y-rhythm2 text-primary-800 2xl:space-y-0">
          {blocks.map((block, index) => {
            const isOdd = !!(index % 2);

            if (!block || block.__typename === 'StrapiError') {
              return null;
            }

            const icon = block.icon;
            const mediaContainerWidth = makeClassNames(
              'w-2/5',
              isOdd && 'xl:w-3/5'
            );

            const content = (
              <>
                <div
                  className={makeClassNames(
                    'relative flex h-full w-3/5 max-w-rhythm13 flex-col',
                    isOdd ? 'xl:w-2/5' : 'lg:w-3/5 2xl:pr-rhythm7',
                    index === 1 && 'min-h-rhythm12: xl:min-h-rhythm13',
                    index === 2 ? 'justify-start' : 'justify-center'
                  )}
                >
                  <div
                    className={makeClassNames(
                      'rounded-2xl px-rhythm3 xl:px-0',
                      index === 1 ? '' : 'py-rhythm5'
                    )}
                  >
                    {/* Border */}
                    <div
                      className={makeClassNames(
                        contentBorderWidth,
                        '] pointer-events-none hidden xl:block',
                        'absolute -mx-rhythm3 rounded-2xl border-primary',
                        index === 0 &&
                          '-my-rhythm2 h-full w-full translate-y-rhythm1',
                        index === 1 &&
                          'h-[920px] w-14/10 max-w-rhythm12 -translate-x-[4rem] -translate-y-[300px]',
                        index === 2 && 'h-12/10 w-full -translate-y-[300px]'
                      )}
                    />
                    <div
                      className={makeClassNames(
                        index === 0 && 'pt-rhythm2',
                        index === 1 && 'my-rhythm4 xl:-mt-rhythm4',
                        index === 2 && 'py-rhythm5'
                      )}
                    >
                      {icon && (
                        <div
                          className={makeClassNames(
                            'h-rhythm7 w-rhythm7',
                            index !== 2 &&
                              'flex items-center rounded-full border-8 border-primary bg-pearl-200 p-4'
                          )}
                        >
                          <img
                            className="h-auto w-full"
                            loading={eager ? undefined : 'lazy'}
                            src={icon.url}
                          />
                        </div>
                      )}
                      <div className="xl:max-w-rhythm11">
                        <Heading color="" level={2}>
                          <Balancer>{block.title}</Balancer>
                        </Heading>
                        <SlateContent bodyColor="" content={block.c} />
                        <ContentLinks index={index} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );

            if (block.__typename === 'StrapiComponentPageBlockWithVideo') {
              return (
                <div
                  key={block.title}
                  className="relative flex space-x-rhythm4 2xl:space-x-0"
                >
                  {content}
                  <div
                    className={makeClassNames(
                      'sticky top-0 z-10 flex h-screen justify-end',
                      mediaContainerWidth,
                      index === 0 && '-mt-rhythm5 pb-rhythm1',
                      index === 2 && '-mb-rhythm6'
                    )}
                  >
                    <MovingImage
                      className={makeClassNames(
                        lgMediaClassNames,
                        'h-full object-cover'
                      )}
                      video={block.video}
                    />
                  </div>
                </div>
              );
            }

            const image = block.image.landscape;

            return (
              <div key={block.title} className="relative flex space-x-rhythm0">
                <div
                  className={makeClassNames(
                    'relative z-10 flex items-start',
                    mediaContainerWidth
                  )}
                >
                  {!!image && (
                    <ResponsiveImage
                      data={image}
                      className={makeClassNames(lgMediaClassNames)}
                      display={'block xl:hidden'}
                      eager={eager}
                    />
                  )}
                  <div className="sticky top-0 -mt-5 hidden w-11/10 xl:block">
                    {!!image && (
                      <ResponsiveImage
                        data={image}
                        className={makeClassNames(
                          '-ml-[20%]',
                          lgMediaClassNames
                        )}
                        height="h-rhythm11"
                        eager={eager}
                      />
                    )}
                  </div>
                </div>
                {content}
              </div>
            );
          })}
        </Container>
      </div>
      <TrackSectionView
        eventFactory={() => ({
          name: AppEventName.SawAllUsp
        })}
      />
    </>
  );
};

export default Usp;
