import { deductAmount, priceToString } from 'lib/l10n';

import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';
import React from 'react';
import { invoicedDiscountStore } from 'state/stores/checkout';
import { makeClassNames } from 'lib/util';
import { useReadable } from 'lib/react-svelte/reactifyStores';

type FormattedPriceProps = Readonly<{
  discount?: GqlSchemaWorker.DiscountFragment | null;
  priceIntegerWithDecimals: number;
  color?: string;
}>;

const FormattedPrice: React.FC<FormattedPriceProps> = ({
  color,
  discount,
  priceIntegerWithDecimals
}) => {
  const currentDiscount = useReadable(invoicedDiscountStore);
  const priceFormatted = priceToString(priceIntegerWithDecimals);
  const appliedDiscount = discount || currentDiscount;

  if (appliedDiscount) {
    const discountedPriceFormatted = priceToString(
      deductAmount(priceIntegerWithDecimals, appliedDiscount.amount)
    );

    return (
      <>
        <span
          className={makeClassNames(
            'mr-1 font-semibold',
            color || 'text-emerald-600'
          )}
        >
          {discountedPriceFormatted}
        </span>
        <span
          className={makeClassNames('line-through', color || 'text-red-700')}
        >
          {priceFormatted}
        </span>
      </>
    );
  }

  return <>{priceFormatted}</>;
};

export default FormattedPrice;
