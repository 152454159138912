import SlateContent, {
  SlateContentParsed
} from 'components/molecules/cms/SlateContent';

import { AppEventName } from 'lib/events/contracts';
import Balancer from 'react-wrap-balancer';
import Carousel from 'components/molecules/carousel/Carousel';
import { Container } from 'components/atoms/layout/Container';
import { Heading } from 'components/atoms/typography';
import { LinkButton } from 'components/atoms/button';
import React from 'react';
import StrapiProductCard from 'components/organisms/product/ProductCard';
import { StrapiProductCardFragment } from 'gatsby/graphqlTypes';
import TrackSectionView from 'lib/events/TrackSectionView';
import { makeClassNames } from 'lib/util';
import { makeProductCatPath } from 'lib/navigation/dynamic';
import { useAppNavTree } from 'state/context/nav';

export type CategoryHighlightProps = Readonly<{
  className?: string;
  slug: string;
  title: string;
  text: SlateContentParsed;
  products: StrapiProductCardFragment[];
  cardClassName?: string;
  icon?: React.ReactNode;
  trackName: string;
}>;

const CategoryHighlight: React.FC<CategoryHighlightProps> = ({
  cardClassName,
  className,
  icon,
  products,
  slug,
  text,
  title,
  trackName
}) => {
  const navTree = useAppNavTree();
  const href = makeProductCatPath(navTree, slug);

  const productCards = products.map(product => (
    <StrapiProductCard
      key={product.id}
      data={product}
      className={makeClassNames(cardClassName, 'h-full')}
    />
  ));

  return (
    <div
      className={makeClassNames(
        'grid grid-cols-12 items-end lg:gap-rhythm2',
        className
      )}
    >
      <Container className="relative col-span-12 flex h-full items-center rounded-lg text-primary sm:bg-pearl-300 sm:py-rhythm3 xl:col-span-5">
        <div className="max-w-rhythm11">
          <Heading level={2}>
            <Balancer>{title}</Balancer>
          </Heading>
          <SlateContent content={text} />
          <div className="mt-rhythm2 hidden lg:block">
            <LinkButton to={href}>View All</LinkButton>
          </div>
        </div>
      </Container>

      <div className="col-span-12 md:mt-rhythm2 xl:col-span-7">
        {icon && <Container className="flex justify-center">{icon}</Container>}
        <Carousel animate cards={productCards} />
      </div>

      <div className="col-span-12 mt-rhythm0 lg:hidden xl:mt-rhythm2">
        <Container>
          <LinkButton to={href}>View All</LinkButton>
        </Container>
      </div>
      <TrackSectionView
        eventFactory={() => ({
          name: AppEventName.SawProductCatHighlight,
          payload: {
            name: trackName
          }
        })}
      />
    </div>
  );
};

export default CategoryHighlight;
