import { CdnImageDataFragment, HomePageQuery } from 'gatsby/graphqlTypes';

import { AppEventName } from 'lib/events/contracts';
import FullWidthImage from '@svelte/reactify/FullWidthImage';
import { Heading } from 'components/atoms/typography';
import { LinkButton } from 'components/atoms/button';
import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
import React from 'react';
import SlateContent from 'components/molecules/cms/SlateContent';
import TrackSectionView from 'lib/events/TrackSectionView';
import { useRoutePath } from 'state/context/nav';

type MissionDrivenProps = Readonly<{
  data: NonNullable<HomePageQuery['strapiHomePage']>['company'];
}>;

const MissionDriven: React.FC<MissionDrivenProps> = ({ data }) => {
  const ourStoryRoute = useRoutePath(MAIN_NAVIGATION_ROUTER_KEY.OUR_STORY);
  const cdnImage = data.image.landscape as CdnImageDataFragment;

  return (
    <>
      <div className="relative flex w-full flex-col lg:flex-col-reverse">
        <FullWidthImage data={cdnImage} height="h-rhythm12 xl:h-rhythm13" />
        <div className="relative z-10 xl:absolute xl:top-0 xl:flex xl:h-full xl:items-center">
          <div className="bg-primary px-4 py-rhythm4 text-secondary sm:px-6 lg:-mb-rhythm10 lg:ml-rhythm3 lg:w-rhythm11 lg:rounded lg:px-12 lg:py-rhythm2 lg:opacity-95 xl:mb-0 xl:ml-rhythm6 xl:bg-opacity-80 xl:backdrop-blur">
            <Heading
              level={2}
              withoutSpacing
              color=""
              bold
              className="mb-rhythm1"
            >
              {data.title}
            </Heading>
            <SlateContent
              bodyColor=""
              className="xl:max-w-rhythm10"
              content={data.text}
            />
            <div className="mt-rhythm3">
              <LinkButton kind="ghostSecondary" to={ourStoryRoute.path}>
                Read Our Story
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
      <TrackSectionView
        eventFactory={() => ({
          name: AppEventName.SawMissionDriven
        })}
      />
    </>
  );
};

export default MissionDriven;
