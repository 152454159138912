// import ExternalCertifications from 'components/molecules/banners/certifications/ExternalCertification';
import CategoryHighlight, {
  CategoryHighlightProps
} from 'components/organisms/product-category/CategoryHighlight';
import {
  CdnImageDataFragment,
  HomePageQuery,
  StrapiProductCardFragment
} from 'gatsby/graphqlTypes';
import { Heading, TextCase } from 'components/atoms/typography';

import { AppPageProps } from 'gatsby/types';
import Balancer from 'react-wrap-balancer';
import CompanyReviews from 'lib/reviews/CompanyReviewsIo';
import HeroBanner from 'components/molecules/banners/Hero';
import MissionDriven from 'components/organisms/home/Mission';
import PrimaryLogo from 'components/atoms/icons/logo/primary/icon';
import React from 'react';
import { SlateContentParsed } from 'components/molecules/cms/SlateContent';
import SolidBarWashes from 'components/molecules/product/SolidBarWashes';
import Usp from 'components/organisms/home/Usp';
import { graphql } from 'gatsby';
import PageContentLayout from 'components/layouts/PageContentLayout';

export { Head } from 'components/atoms/Head';

export const query = graphql`
  query HomePage {
    strapiHomePage {
      bannerImages {
        ...CdnImageData
      }
      company {
        text: textSlate {
          ...SlateContent
        }
        title
        image {
          __typename
          landscape {
            ...CdnImageData
          }
        }
      }
      usp {
        __typename
        ... on StrapiComponentPageBlockWithOrientedImage {
          c: textSlate {
            ...SlateContent
          }
          title
          icon {
            altText: alternativeText
            url
          }
          image {
            __typename
            landscape {
              ...CdnImageData
            }
          }
        }
        ... on StrapiComponentPageBlockWithVideo {
          c: textSlate {
            ...SlateContent
          }
          title
          icon {
            altText: alternativeText
            url
          }
          video {
            ...ResponsiveVideo
          }
        }
      }
      cats: productCategories {
        text: textSlate {
          ...SlateContent
        }
        title
        productCategory {
          name
          pageMetadata {
            slug
          }
          products {
            ...StrapiProductCard
          }
        }
      }
    }
  }
`;

type Props = AppPageProps<HomePageQuery>;

const IndexPage: React.FC<Props> = ({ data, pageContext }) => {
  const cmsData = data.strapiHomePage;
  if (!cmsData) {
    throw Error('No HomePage data');
  }

  const { bannerImages, company, usp } = cmsData;
  const cats: CategoryHighlightProps[] = cmsData.cats.map(cat => ({
    slug: cat?.productCategory?.pageMetadata.slug as string,
    title: cat?.title as string,
    text: cat?.text as SlateContentParsed,
    products: cat?.productCategory?.products as StrapiProductCardFragment[],
    trackName: cat?.productCategory?.name as string
  }));

  return (
    <PageContentLayout footerNavigation={pageContext.footerNavigation}>
      <HeroBanner bannerImages={bannerImages as CdnImageDataFragment[]} />
      <main className="hyphens-none">
        <div className="flex flex-col items-center bg-primary p-rhythm4">
          <div className="flex flex-col items-center space-y-rhythm4 text-center md:max-w-rhythm12 lg:flex-row lg:space-y-0 lg:text-left">
            <PrimaryLogo className="w-rhythm7 flex-grow-0 fill-secondary lg:mr-9" />
            <Heading
              level={2}
              color="text-secondary"
              withoutSpacing
              className="flex-1"
              textCase={TextCase.None}
            >
              <Balancer>For people and planet</Balancer>
            </Heading>
          </div>
        </div>
        {/* <Container
          id="product-preview"
          as="section"
          aria-labelledby="product-preview"
        >
          <div className="mb-rhythm3 flex w-full flex-col items-center justify-center xl:mb-rhythm5 2xl:flex-row">
            <div className="flex-1">
              <Heading level={2} className="text-center 2xl:text-left">
                <Balancer>Introducing our Multi-Purpose Shampoo Bars</Balancer>
              </Heading>
              <BodyText>
                <Balancer>
                  High-performant formulae to shampoo, condition, cleanse and
                  shave.
                </Balancer>
              </BodyText>
            </div>

            <SolidBarWashes />
          </div>
          <ProductList cardSize="small" products={data.products.nodes || []} />
        </Container> */}
        <Usp blocks={usp} />
        <CategoryHighlight
          cardClassName="2xl:w-rhythm10 w-rhythm9"
          className="pb-rhythm4 md:py-rhythm6 xl:py-rhythm8"
          icon={<SolidBarWashes className="mb-rhythm2" />}
          {...cats[0]}
        />
        <MissionDriven data={company} />
        <CategoryHighlight
          cardClassName="2xl:w-rhythm10 w-rhythm9"
          className="md:py-rhythm4 xl:pt-rhythm8"
          {...cats[1]}
        />

        {/* <Container className="flex justify-center py-rhythm2">
          <ExternalCertifications className="max-w-rhythm12" />
        </Container> */}

        <div className="mt-rhythm4 bg-primary-200 py-rhythm0 md:py-rhythm2">
          <CompanyReviews />
        </div>
      </main>
    </PageContentLayout>
  );
};

export default IndexPage;
