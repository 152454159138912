import React from 'react';

type PlasticBottleProps = Readonly<{ className?: string }>;

const PlasticBottle: React.FC<PlasticBottleProps> = ({ className }) => {
  return (
    <svg
      className={className}
      version="1.1"
      viewBox="0 0 217.97 512"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-147.02)">
        <path d="m343.47 105.36c-8.596-8.484-18.957-14.553-30.24-17.879v-44.126c0-23.904-19.449-43.352-43.353-43.352h-27.76c-23.906 0-43.353 19.448-43.353 43.352v44.127c-11.283 3.327-21.644 9.395-30.24 17.879-13.669 13.49-21.51 32.24-21.51 51.446v355.2h217.97v-355.2c-1e-3 -19.204-7.842-37.956-21.511-51.446zm-111.32-62.005c1e-3 -5.492 4.47-9.961 9.963-9.961h27.76c5.494 0 9.962 4.469 9.962 9.961v41.173h-47.685zm-51.748 435.26v-321.81c0-10.332 4.218-20.421 11.572-27.679 7.324-7.228 17.025-11.208 27.315-11.208h73.409c10.29 0 19.991 3.98 27.315 11.208 7.354 7.258 11.572 17.346 11.572 27.679v321.8h-151.18z" />

        <path d="m194.78 223.84v164.49h122.44v-164.49zm89.044 131.1h-55.652v-97.709h55.652z" />
      </g>
    </svg>
  );
};

export default PlasticBottle;
