<script lang="ts" context="module">
  import { assertUnreachable, makeClassNames } from 'lib/util';

  function getName(campaignKind: GqlSchemaWorker.DiscountCampaignKind) {
    switch (campaignKind) {
      case 'NEWSLETTER_SIGN_UP':
        return 'Welcome!';
      case 'RETENTION':
        return 'Loyalty';
      default:
        return assertUnreachable(campaignKind);
    }
  }

  function formatAmount({ amount }: GqlSchemaWorker.DiscountFragment) {
    const { __typename } = amount;
    switch (__typename) {
      case 'DiscountPercentage':
        return `${amount.value}%`;
      case 'DiscountCurrency':
        return `${amount.amount}`;
      case 'DiscountFreeDelivery':
        return '';
      default:
        return assertUnreachable(__typename);
    }
  }
</script>

<script lang="ts">
  import DiscountIcon from '@svelte/components/atoms/icons/Discount.svelte';
  import { invoicedDiscountStore } from 'state/stores/checkout';
  import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';

  export let className: string | null = null;
  export let discount: GqlSchemaWorker.DiscountFragment | null = null;

  let _discount = discount || $invoicedDiscountStore;
  const name = _discount && getName(_discount.campaignKind);
  const amount = _discount && formatAmount(_discount);
</script>

{#if !!_discount}
  <div
    class={makeClassNames(
      className,
      'inline-flex h-rhythm2 flex-nowrap items-center justify-center space-x-2 rounded border border-red-800 bg-red-300 px-2 py-2 text-red-800'
    )}
  >
    <DiscountIcon />
    <!-- TODO: InterfaceText -->
    <span class="inline-block shrink-0 text-xs"
      >{name}{amount && ` -${amount}`}</span
    >
  </div>
{/if}
