import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Container } from 'components/atoms/layout/Container';
import { InView } from 'react-intersection-observer';
import debounce from 'lodash.debounce';
import { makeClassNames } from 'lib/util';
import Chevron from 'components/atoms/icons/Chevron';

const CAROUSEL_DEBOUNCE_INTERVAL = 500;

type CarouselChevronProps = {
  onClick: () => void;
  disabled: boolean;
  direction: 'forward' | 'backward';
};

const CarouselChevron: React.FC<CarouselChevronProps> = ({
  onClick,
  disabled,
  direction
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className="h-10 w-10"
    >
      <Chevron
        direction={direction === 'forward' ? 'right' : 'left'}
        className={makeClassNames(
          'h-full w-full duration-500  transform',
          disabled ? 'fill-pearl-700 scale-75' : 'fill-primary scale-100'
        )}
      />
    </button>
  );
};

const opacityTransitionClassNames = 'transform duration-2000';

type CarouselProps = Readonly<{
  animate: boolean;
  className?: string;
  cardClassName?: string;
  cards: React.ReactNode[];
  onCardClicked?: (index: number) => void;
}>;

const Carousel: React.FC<CarouselProps> = ({
  animate,
  cardClassName,
  className,
  cards,
  onCardClicked
}) => {
  const cardRefs = useRef<HTMLDivElement[]>([]);
  const currentPosition = useRef(0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const itemWidth = useRef(0);
  const [carouselState, setCarouselState] = useState({
    isPrevHidden: true,
    isNextHidden: false
  });
  const secretsInView = useRef<Set<number>>(new Set());
  const [secretInFocus, setSecretInFocus] = useState({
    dirty: false,
    index: -1
  });
  const [showControls, setShowControls] = useState(true);

  /**
   * Handle carousel width change
   */
  useEffect(() => {
    // NOTE: this carousel does not support cards of varying width
    const firstCardWith = cardRefs.current[0]?.clientWidth;

    if (firstCardWith) {
      itemWidth.current = firstCardWith;
    }

    const handleCarouselWidthChange = () => {
      const el = carouselRef.current;
      if (el) {
        const { clientWidth, scrollWidth } = el;
        setShowControls(scrollWidth > clientWidth);
      }
    };

    handleCarouselWidthChange();
    window.addEventListener('resize', handleCarouselWidthChange);

    return () =>
      window.removeEventListener('resize', handleCarouselWidthChange);
  }, [cards]);

  /**
   * Handle scroll changes
   */
  useEffect(() => {
    const el = carouselRef.current;
    if (el) {
      const handleScroll = () => {
        const { clientWidth, scrollWidth, scrollLeft } = el;
        const { isPrevHidden, isNextHidden } = carouselState;

        if (clientWidth === scrollWidth) {
          return;
        }

        if (scrollLeft < 100) {
          if (!isPrevHidden || isNextHidden) {
            setCarouselState({
              isPrevHidden: true,
              isNextHidden: false
            });
          }
        } else if (clientWidth + scrollLeft === scrollWidth) {
          if (isPrevHidden || !isNextHidden) {
            setCarouselState({
              isPrevHidden: false,
              isNextHidden: true
            });
          }
        } else if (isPrevHidden || isNextHidden) {
          setCarouselState({
            isPrevHidden: false,
            isNextHidden: false
          });
        }
      };
      el.addEventListener(
        'scroll',
        debounce(handleScroll, 500, {
          leading: false,
          trailing: true
        })
      );
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [carouselState]);

  /**
   * Handle carousel width change
   */
  useEffect(() => {
    // NOTE: this carousel does not support cards of varying width
    const firstCardWith = cardRefs.current[0]?.clientWidth;

    if (firstCardWith) {
      itemWidth.current = firstCardWith;
    }

    const handleCarouselWidthChange = () => {
      const el = carouselRef.current;
      if (el) {
        const { clientWidth, scrollWidth } = el;
        setShowControls(scrollWidth > clientWidth);
      }
    };

    handleCarouselWidthChange();
    window.addEventListener('resize', handleCarouselWidthChange);

    return () =>
      window.removeEventListener('resize', handleCarouselWidthChange);
  }, [cards]);

  useEffect(() => {
    if (secretInFocus.dirty) {
      let indexInFocus = secretInFocus.index;
      const { current: secretRefs } = cardRefs;
      const { current } = secretsInView;
      const total = current.size;
      const lastIndex = secretRefs.length - 1;
      const values = Array.from(current.values()).sort();

      if (total == 2) {
        if (current.has(0)) {
          indexInFocus = 0;
        } else if (current.has(lastIndex)) {
          indexInFocus = lastIndex;
        }
      } else if (total === 3) {
        indexInFocus = values[1];
      }

      setSecretInFocus({
        dirty: false,
        index: indexInFocus
      });
    }
  }, [secretInFocus.dirty, showControls]);

  /**
   * NOTE: debounce is required to avoid event handlers being called before scroll finishes
   * State can't be accessed from callback as is
   */
  const scrollNext = useCallback(
    debounce(
      () => {
        const totalCards = cardRefs.current.length;

        let nextIndex = currentPosition.current + 1;
        if (nextIndex >= totalCards) {
          nextIndex = 0;
        }

        carouselRef.current?.scrollBy({
          left: nextIndex ? itemWidth.current : -itemWidth.current * totalCards,
          top: 0,
          behavior: 'smooth'
        });

        currentPosition.current = nextIndex;
      },
      CAROUSEL_DEBOUNCE_INTERVAL,
      { leading: true, trailing: false, maxWait: CAROUSEL_DEBOUNCE_INTERVAL }
    ),
    []
  );

  const scrollPrev = useCallback(
    debounce(
      () => {
        const prevIndex = currentPosition.current
          ? currentPosition.current - 1
          : 0;

        carouselRef.current?.scrollBy({
          left: -itemWidth.current,
          top: 0,
          behavior: 'smooth'
        });

        currentPosition.current = prevIndex;
      },
      CAROUSEL_DEBOUNCE_INTERVAL,
      { leading: true, trailing: false, maxWait: CAROUSEL_DEBOUNCE_INTERVAL }
    ),
    []
  );

  return (
    <>
      {showControls && (
        <Container
          className={makeClassNames(
            'mb-rhythm0 flex justify-end',
            opacityTransitionClassNames,
            animate ? 'opacity-100' : 'opacity-0'
          )}
        >
          <CarouselChevron
            onClick={scrollPrev}
            disabled={carouselState.isPrevHidden}
            direction="backward"
          />
          <CarouselChevron
            onClick={scrollNext}
            disabled={carouselState.isNextHidden}
            direction="forward"
          />
        </Container>
      )}
      <div
        className={makeClassNames(
          'flex w-full cursor-pointer snap-x snap-mandatory gap-6 overflow-x-auto px-6 pr-8 pb-10 scrollbar-thin scrollbar-none',
          className,
          !showControls && 'justify-center'
        )}
        ref={carouselRef}
      >
        {/*
         * Render card
         */}
        {cards.map((card, index) => {
          const [transitionEnded, setTransitionEnded] = useState(false);
          return (
            <InView
              as="div"
              key={index}
              onTransitionEnd={event => {
                if (!transitionEnded && event.propertyName === 'translate') {
                  setTransitionEnded(true);
                }
              }}
              className={makeClassNames(
                'relative shrink-0 drop-shadow-lg',
                'duration-2000 ease-in-out transform',
                index > 0 && 'delay-300',
                animate
                  ? 'opacity-100 translate-x-0'
                  : 'opacity-0 translate-x-3/4',
                transitionEnded && 'snap-center',
                cardClassName
              )}
              onChange={inView => {
                /**
                 * Batch changes into a reference
                 * Otherwise only the last card to set index would be stored
                 */
                const { current } = secretsInView;
                if (inView && !current.has(index)) {
                  current.add(index);
                  setSecretInFocus({
                    ...secretInFocus,
                    dirty: true
                  });
                } else if (!inView && current.has(index)) {
                  secretsInView.current.delete(index);
                  setSecretInFocus({
                    ...secretInFocus,
                    dirty: true
                  });
                }
              }}
              onClick={() => {
                /**
                 * Handle user clicking on card on the sides of carousel
                 * Make sure the one clicked/tapped is on center
                 */
                if (secretInFocus.index !== index) {
                  if (secretInFocus.index < index) {
                    scrollNext();
                  } else {
                    scrollPrev();
                  }
                }

                onCardClicked && onCardClicked(index);
              }}
            >
              {/*
               * Card front face
               */}
              <div
                className="h-full"
                ref={element =>
                  element &&
                  cards.length > cardRefs.current.length &&
                  cardRefs.current.push(element)
                }
              >
                {card}
              </div>
            </InView>
          );
        })}
      </div>
    </>
  );
};

export default Carousel;
