<script lang="ts">
  import { makeClassNames } from 'lib/util';

  export let className: string | null = null;
  export let color = 'stroke-red-800';
  export let stroke = 'stroke-2';

  const _className = makeClassNames(className, color, stroke, [
    'fill-none',
    'w-rhythm0'
  ]);
</script>

<svg
  class={_className}
  viewBox="0 0 32 32"
  version="1.1"
  xml:space="preserve"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <polygon points="2,20 18,4 28,4 28,14 12,30" />
  <circle cx="23" cy="9" r="2" />
  <line x1="24" x2="30" y1="8" y2="2" />
  <line x1="17" x2="9" y1="11" y2="19" />
  <line x1="21" x2="17" y1="15" y2="19" />
</svg>
