import React from 'react';

type SolidBarProps = Readonly<{ className?: string }>;

const SolidBar: React.FC<SolidBarProps> = ({ className }) => {
  return (
    <svg
      className={className}
      version="1.1"
      viewBox="0 0 18 12.208"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m9 12.208c5.131 0 9-1.1811 9-2.7467v-6.409h-0.053c0.033-0.1001 0.053-0.20143 0.053-0.30519 0-1.5656-3.869-2.7467-9-2.7467-4.791 0-8.48 1.0303-8.947 2.4415h-0.053v7.0194c0 1.5656 3.869 2.7467 9 2.7467zm0-1.2208c-4.273 0-7-0.90337-7-1.526v-4.9478c1.623 0.60611 4.111 0.98028 7 0.98028s5.377-0.37417 7-0.98028v4.9478c0 0.62259-2.727 1.526-7 1.526zm0-9.7662c4.273 0 7 0.90337 7 1.526 0 0.62259-2.727 1.526-7 1.526s-7-0.90337-7-1.526c0-0.62259 2.727-1.526 7-1.526z"
        strokeWidth=".78127"
      />
    </svg>
  );
};

export default SolidBar;
