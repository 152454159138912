import { InterfaceText, TextSize } from 'components/atoms/typography';

import PlasticBottle from 'components/atoms/icons/PlasticBottle';
import React from 'react';
import SolidBar from 'components/atoms/icons/SolidBar';
import { makeClassNames } from 'lib/util';

const EqualSign: React.FC = () => (
  <InterfaceText size={TextSize.DoublePica} withoutSpacing bold color="">
    =
  </InterfaceText>
);

type SolidBarWashesProps = Readonly<{
  className?: string;
}>;

const SolidBarWashes: React.FC<SolidBarWashesProps> = ({ className }) => {
  return (
    <div
      className={makeClassNames(
        className,
        // NOTE: grid-rows-[80px_auto] doesn't work on Safari
        'grid min-w-[320px] max-w-xs auto-cols-auto grid-rows-[80px_20px] items-center justify-items-center gap-x-rhythm0 text-primary sm:gap-x-rhythm1'
      )}
    >
      {/* First row */}
      <SolidBar className="min-h-rhythm2 max-w-rhythm3 flex-1 fill-primary lg:w-rhythm2" />
      <EqualSign />
      <div className="flex h-full flex-col items-center justify-center">
        <InterfaceText size={TextSize.Brevier} color="" bold withoutSpacing>
          Up to
        </InterfaceText>
        <InterfaceText
          size={TextSize.GreatPrimer}
          color=""
          bold
          withoutSpacing
          className="-mt-2"
        >
          70
        </InterfaceText>
      </div>
      <EqualSign />
      <div className="flex items-center justify-center space-x-2">
        {Array.from(Array(3)).map((_, i) => {
          return (
            <span key={i} className="flex h-rhythm2 items-center">
              <PlasticBottle
                className={makeClassNames('h-full fill-primary')}
              />
              {i !== 2 && <span className="ml-2">+</span>}
            </span>
          );
        })}
      </div>

      {/* Second row */}
      <InterfaceText
        size={TextSize.LongPrimer}
        color=""
        className="col-span-1"
        bold
      >
        1 Bar
      </InterfaceText>
      <InterfaceText
        size={TextSize.LongPrimer}
        color=""
        bold
        className="col-span-1 col-start-3"
      >
        Washes
      </InterfaceText>
      <InterfaceText
        size={TextSize.LongPrimer}
        className="col-span-1 col-start-5"
        color=""
        bold
      >
        3 Plastic Bottles
      </InterfaceText>
    </div>
  );
};

export default SolidBarWashes;
