import {
  BodyText,
  Heading,
  InterfaceText,
  TextSize
} from 'components/atoms/typography';

import Balancer from 'react-wrap-balancer';
import { DiscountTag } from '@svelte/reactify/DiscountTag';
import FormattedPrice from 'components/molecules/product/FormattedPrice';
import { Link } from 'gatsby';
import ProductThumbnail from '@svelte/reactify/ProductThumbnail';
import React from 'react';
import { StrapiProductCardFragment } from 'gatsby/graphqlTypes';
import { TextButton } from 'components/atoms/button';
import ViewIcon from '@carbon/icons-react/es/view/24';
import { makeClassNames } from 'lib/util';
import { makeProductPath } from 'lib/navigation/dynamic';
import { useAppNavTree } from 'state/context/nav';
import { useWritable } from 'lib/react-svelte/reactifyStores';
import { productQuickViewSelectedStore } from '@svelte/state/productQuickView';

type ProductNameProps = {
  href: string;
  name: string;
};

const ProductName: React.FC<ProductNameProps> = ({ href, name }) => {
  return (
    <Heading
      className="mb-rhythm-3"
      level={3}
      mobileSize={TextSize.Pica}
      withoutSpacing
      bold
    >
      <Balancer>
        <Link to={href}>
          <span aria-hidden="true" className="absolute inset-0" />
          {name.trim()}
        </Link>
      </Balancer>
    </Heading>
  );
};

const transitionClassNames = ['transition-colors', 'duration-500'];

export type CardProductFunction = {
  name: string;
  shortName?: string;
};

const StrapiProductCard: React.FC<{
  className?: string;
  data: StrapiProductCardFragment;
  responsive?: boolean;
}> = ({ className, data, responsive }) => {
  const { minPrice, maxPrice } = data;
  const appNav = useAppNavTree();
  const [, updateQuickViewProduct] = useWritable(productQuickViewSelectedStore);
  const href = makeProductPath(appNav, data.pageMetadata.slug);

  const openQuickView = () => updateQuickViewProduct(data);
  const image = data.image;

  return (
    <div
      key={data.name}
      className={makeClassNames(
        'bg group relative flex flex-1 flex-col overflow-hidden rounded-lg',
        className
      )}
    >
      <div
        className={makeClassNames(
          transitionClassNames,
          'flex-1 bg-pearl-300 group-hover:bg-white',
          responsive
            ? 'grid h-rhythm8 grid-cols-12 2xl:block 2xl:h-auto'
            : 'h-full'
        )}
      >
        {/* Product thumbnail */}
        <div
          className={makeClassNames(
            'relative col-span-5',
            responsive ? 'h-full max-h-full 2xl:h-rhythm8' : 'h-rhythm8'
          )}
        >
          {!!image && (
            <ProductThumbnail
              productName={data.name}
              fileName={image.cdn?.fileName as string}
              thumbhash={image.thumbhash as number[]}
            />
          )}

          {/* Mobile QuickView icon */}
          <button
            type="button"
            onClick={openQuickView}
            className="absolute top-0 z-10 flex h-11 w-11 cursor-pointer items-center justify-center hover:stroke-primary xl:hidden"
          >
            <ViewIcon className="fill-primary-400" />
          </button>

          {/* Desktop QuickView hover button */}
          <div className="absolute top-0 z-10 mt-rhythm0 hidden w-full items-center justify-center px-5 transition-opacity xl:flex xl:opacity-0 xl:group-hover:opacity-100">
            <TextButton size="xs" kind="ghost" onClick={openQuickView}>
              Quick View
            </TextButton>
          </div>
        </div>

        {/* Product information */}
        <div className="col-span-7 flex flex-col justify-center px-4 py-rhythm2">
          <div className="mb-rhythm0">
            <ProductName href={href} name={data.name} />
            <div>
              {minPrice !== maxPrice && (
                <InterfaceText size={TextSize.Brevier} className="mr-1">
                  From
                </InterfaceText>
              )}
              <InterfaceText className="inline-block">
                <FormattedPrice priceIntegerWithDecimals={minPrice} />
              </InterfaceText>
            </div>
            <DiscountTag className="mt-1" />
          </div>

          <BodyText
            withoutSpacing
            size={TextSize.LongPrimer}
            className="max-w-rhythm8"
          >
            <Balancer>{(data.descriptor || '').trim()}</Balancer>
          </BodyText>
        </div>
      </div>
    </div>
  );
};

export default StrapiProductCard;
